import React, { useCallback, useMemo, useState } from 'react'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import 'react-datepicker/dist/react-datepicker.css'
import { useNavigate } from 'react-router-dom'
import { post } from '../api/base'
import { ValueLabelOptionType } from '../components/SelectInputField'
import { Spinner } from 'flowbite-react'
import breedOptionsData from '../fixtures/breed-list.json'
import colorOptions from '../fixtures/color-list.json'
import disciplineOptions from '../fixtures/discipline-list.json'
import { useHorseAvatarUpdate } from '../hooks/useHorseAvatarUpdate'
import { Heading } from '../components/Heading'
import { ScreenLayout } from '../components/ScreenLayout'
import { Button } from '../components/Button'
import { DatePicker, Input } from '@edx/react-common'
import { FaArrowLeft, FaBan, FaCheck, FaPlus } from 'react-icons/fa'
import { useCheckoutState } from '../state/checkout'
import { useHorses } from '../hooks/useHorses'
import { useRegistries } from '../hooks/useRegistries'
import { FormikTextInputField } from '../components/FormikTextInputField'
import { FormikSelectInputField } from '../components/FormikSelectInputField'

const ValidationSchema = Yup.object({
  horseName: Yup.string().required('Required'),
  horseBreed: Yup.string().required('Required'),
  horseSex: Yup.string().required('Required'),

  /** Note: The following options are not required in the backend
   *  call but can be uncommented if we desire extra validation
   *  and/or backend requirements change. */

  // horseDoB: Yup.string().required("Required"),
  // horseColor: Yup.string().required("Required"),
  // horseDiscipline: Yup.string().required("Required"),
})

const baseHorsePayload: Record<string, any> = {
  name: null,
  comments: '',
  gender: null,
  temperament: 0,
  breeds: null,
  colors: null,
  avatar: null,
  location: null,
  address: { country: '', state: '', city: '', street: '', zip: '' },
  preclinicalNotes: null,
  parentage: [
    { registries: [], name: '', parent: 'Sire' },
    { registries: [], name: '', parent: 'Dam' },
  ],
  registries: null,
  birthDate: { birthDate: null },
  profileType: 'Private',
  passportNumber: null,
  microchipNumber: null,
  ancestryProfileType: 'Private',
  commercialTypes: [],
}

const breedOptions = breedOptionsData
  .map((breed) => {
    return {
      label: breed.label[0].toUpperCase() + breed.label.slice(1),
      value: breed.value,
    }
  })
  .sort((a, b) => (a.label < b.label ? -1 : 1))

const AddHorseForm = () => {
  const navigate = useNavigate()
  const { allHorses } = useCheckoutState()

  // Fetch all horses
  const horsesData = useHorses()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const updateHorseAvatar = useHorseAvatarUpdate()
  const [avatar, setAvatar] = useState<File>()
  const [horseId, setHorseId] = useState<number>()

  const { data: registryOptionData, isLoading: registryOptionsLoading } = useRegistries()

  const handleSubmit = useCallback(async (values) => {
    setIsLoading(true)

    // Substitute all values in base payload with form values
    baseHorsePayload['name'] = values.horseName
    baseHorsePayload['breeds'] = [+values.horseBreed]
    baseHorsePayload['colors'] = values.horseColor !== '' ? [+values.horseColor] : null
    baseHorsePayload['gender'] = values.horseSex
    baseHorsePayload['birthDate']['birthDate'] = values.horseDoB !== '' ? values.horseDoB : null
    baseHorsePayload['disciplines'] = values.horseDiscipline !== '' ? [+values.horseDiscipline] : null

    if (values.horseDamRegistryId && values.horseDamRegistryValue) {
      baseHorsePayload['parentage'][0] = {
        parent: 'Dam',
        name: '',
        registries: [
          {
            associationId: values.horseDamRegistryId,
            registrationNumber: values.horseDamRegistryValue,
          },
        ],
      }
    }
    if (values.horseSireRegistryId && values.horseSireRegistryValue) {
      baseHorsePayload['parentage'][1] = {
        parent: 'Sire',
        name: '',
        registries: [
          {
            associationId: values.horseSireRegistryId,
            registrationNumber: values.horseSireRegistryValue,
          },
        ],
      }
    }
    // Create horse, log rejection and/or errors if they occur

    let newHorseId = horseId
    let exception = false
    if (!newHorseId) {
      try {
        newHorseId = (await post('/api/HorsesCreate', baseHorsePayload as any)).data
        setHorseId(newHorseId)
      } catch (error) {
        console.error('AddHorseForm#onSubmit /api/HorsesCreate', error)
        exception = true
      }
    }
    if (avatar && newHorseId) {
      try {
        await updateHorseAvatar({ horseId: newHorseId, avatar })
      } catch (error) {
        console.error('AddHorseForm#onSubmit#updateHorseAvatar', error)
        exception = true
      }
    }
    if (!exception) {
      navigate(-1)
    } else {
      setIsLoading(false)
    }
  }, [avatar])

  const registryOptions = (registryOptionData?.data || [])
    .map((registry) => {
      return {
        label: registry.name,
        value: registry.id,
      }
    })
    .sort((a, b) => (a.label < b.label ? -1 : 1))

  // Dropdown options for Horse Sex
  const horseSexOptions: ValueLabelOptionType[] = [
    { value: 'Stallion', label: 'Stallion' },
    { value: 'Gelding', label: 'Gelding' },
    { value: 'Mare', label: 'Mare' },
  ]

  const handleAvatarUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files?.length) return
    setAvatar(files[0])
  }

  if (isLoading)
    return (
      <div className="mt-12 text-center flex flex-col items-center justify-center">
        <Spinner className="fill-edxred-500" size="lg" aria-label="Center-aligned spinner" />
        <p className="whitespace-pre-wrap">Adding horse...</p>
      </div>
    )

  if (horsesData.isLoading || registryOptionsLoading)
    return (
      <div className="mt-12 text-center flex flex-col items-center justify-center">
        <Spinner className="fill-edxred-500" size="lg" aria-label="Center-aligned spinner" />
      </div>
    )

  return (
    <ScreenLayout>
      <Heading>{allHorses.length ? 'Add A New Horse' : 'Add Your First Horse'}</Heading>
      <Formik
        initialValues={{
          horseName: '',
          horseBreed: '',
          horseColor: '',
          horseSex: '',
          horseDoB: '',
          horseDiscipline: '',
          horseImage: null,
          horseSireRegistryValue: '',
          horseSireRegistryId: '',
          horseSireName: '',
          horseDamRegistryValue: '',
          horseDamRegistryId: '',
          horseDamName: '',
        }}
        validateOnBlur
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <div className="mt-6 flex flex-col gap-4 overflow-y-auto scrollbar pb-32">
              <FormikTextInputField fieldName="horseName" label="Horse Name" />
              <FormikSelectInputField fieldName="horseBreed" label="Breed" options={breedOptions} />
              <div className="columns-2">
                <FormikSelectInputField fieldName="horseColor" label="Color" options={colorOptions} />
                <FormikSelectInputField fieldName="horseSex" label="Sex" options={horseSexOptions} />
              </div>
              <div className="flex gap-4">
                <div className="override-datepicker flex-1">
                  <DatePicker
                    buttonClassName="w-full"
                    value={formik.values.horseDoB ? new Date(formik.values.horseDoB) : undefined}
                    onChangeValue={(date) => formik.setFieldValue('horseDoB', date?.toISOString())}
                    placeholder="Birthdate"
                    dateFnsFormat={'MMM yyyy'}
                    disabled={{ after: new Date() }}
                  />
                </div>
                <div className="flex-1">
                  <FormikSelectInputField fieldName="horseDiscipline" label="Discipline" options={disciplineOptions} />
                </div>
              </div>
              <>
                Sire
                <FormikTextInputField fieldName="horseSireName" label="Sire Name" />
                <div className="columns-2">
                  <FormikSelectInputField
                    fieldName="horseSireRegistryId"
                    label="Registry Name"
                    options={registryOptions}
                  />
                  <FormikTextInputField fieldName="horseSireRegistryValue" label="Registry Number" />
                </div>
              </>
              <>
                Dam
                <FormikTextInputField fieldName="horseDamName" label="Dam Name" />
                <div className="columns-2">
                  <FormikSelectInputField
                    fieldName="horseDamRegistryId"
                    label="Registry Name"
                    options={registryOptions}
                  />
                  <FormikTextInputField fieldName="horseDamRegistryValue" label="Registry Number" />
                </div>
              </>
              <Input
                className="focus-visible:ring-0 focus-visible:ring-offset-0"
                onChange={handleAvatarUpload}
                type="file"
              />
              <p className="-mt-2 text-sm text-gray-500">Upload horse profile photo</p>

              <div className="flex space-x-2 w-full">
                <Button className=" space-x-2" outline onClick={() => navigate(-1)}>
                  <FaArrowLeft />
                  <span>Go Back</span>
                </Button>

                <Button
                  className="flex-1 space-x-2"
                  disabled={formik.isSubmitting}
                  onClick={() => formik.handleSubmit()}
                >
                  <FaCheck /> <span className="text-base">Save Horse</span>
                </Button>
              </div>
            </div>
          )
        }}
      </Formik>
    </ScreenLayout>
  )
}

export default AddHorseForm
